'use client'

import { useEffect } from 'react'
import Link from 'next/link'
import { Button, ButtonWidth, Card, Icon } from '~/scalis-components/core'
import { Title, TitleSize, Caption, CaptionColor, Body } from '~/scalis-components/core/typography'
import logger from '~/utils/logger'

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    logger.error('error', error)
  }, [error])

  return (
    <html>
      <body className='flex items-center justify-center bg-background-blue h-screen flex-col gap-2'>
        <Card className='py-16 px-12 bg-neutral-00 flex items-center flex-col rounded-xl gap-8 max-w-sm text-center border-neutral-00'>
          <div className='flex flex-col gap-4 items-center'>
            <div className='flex items-center justify-center size-16'>
              <Icon
                icon='fa-solid fa-circle-exclamation'
                className='text-red-400 fa-4x'
              />
            </div>

            <div className='flex flex-col'>
              <Title size={TitleSize.medium}>Oops, There was a problem.</Title>
              <Body>You can try to reload again or go to our home page.</Body>
            </div>
          </div>

          <Link
            href='/'
            className='w-full'
          >
            <Button width={ButtonWidth.full}>SCALIS Home</Button>
          </Link>
        </Card>
        <Caption color={CaptionColor.secondary}>©2024 SCALIS, LLC. All Rights Reserved.</Caption>
      </body>
    </html>
  )
}
